// node_modules/lzma/src/lzma.js translated to typescript
var Action;
(function (Action) {
    Action[Action["Compress"] = 1] = "Compress";
    Action[Action["Decompress"] = 2] = "Decompress";
    Action[Action["Progress"] = 3] = "Progress";
})(Action || (Action = {}));
export function LZMA() {
    const lzma_worker = new Worker(new URL("./lzma_worker.ts", import.meta.url));
    const callback_obj = {};
    lzma_worker.addEventListener("message", (e) => {
        const { on_progress, on_finish } = callback_obj[e.data.cbn];
        if (e.data.action === Action.Progress) {
            const data = e.data;
            if (on_progress) {
                on_progress(data.result);
            }
        }
        else {
            const data = e.data;
            if (on_finish) {
                if (data.error) {
                    on_finish(undefined, data.error);
                }
                else {
                    if (typeof data.result === "string") {
                        on_finish(new TextEncoder().encode(data.result), undefined);
                    }
                    else {
                        on_finish(new Uint8Array(data.result), undefined);
                    }
                }
            }
            delete callback_obj[e.data.cbn];
        }
    });
    lzma_worker.addEventListener("error", (event) => {
        const err = new Error(`${event.message} (${event.filename}:${event.lineno})`);
        Object.values(callback_obj).forEach(({ on_finish }) => {
            if (on_finish) {
                on_finish(undefined, err);
            }
        });
        console.error("Uncaught error in lzma_worker", err);
    });
    return (function () {
        function setCallbacks(on_finish, on_progress) {
            let cbn;
            do {
                cbn = Math.floor(Math.random() * 10000000);
            } while (typeof callback_obj[cbn] !== "undefined");
            callback_obj[cbn] = { on_progress, on_finish };
            return cbn;
        }
        return {
            compress(data, mode, on_finish, on_progress) {
                const message = {
                    action: Action.Compress,
                    cbn: setCallbacks(on_finish, on_progress),
                    data,
                    mode,
                };
                lzma_worker.postMessage(message);
            },
            decompress(data, on_finish, on_progress) {
                const message = {
                    action: Action.Decompress,
                    cbn: setCallbacks(on_finish, on_progress),
                    data,
                };
                lzma_worker.postMessage(message);
            },
            worker: function worker() {
                return lzma_worker;
            },
        };
    })();
}
