import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { decode, encode } from "./codec";
import "./styles/main";
const element = document.querySelector("#main");
const props = loadProps(element);
if (document.readyState === "complete") {
    props.then(render);
}
else {
    document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
            props.then(render);
        }
    });
}
window.addEventListener("hashchange", () => {
    loadProps(null).then(render);
});
function render(props) {
    if (element?.hasChildNodes()) {
        ReactDOM.hydrate(React.createElement(App, { ...props }), element);
    }
    else {
        ReactDOM.render(React.createElement(App, { ...props }), element);
    }
}
function loadProps(element) {
    const props = {
        setTitle(title) {
            document.title = title || "";
        },
        setContent(content) {
            encode(content).then((hash) => {
                document.location.replace("#" + hash);
            }, (error) => {
                console.error("encoding error", error);
            });
        },
    };
    if (element?.innerHTML) {
        return Promise.resolve({ content: element.innerHTML, ...props });
    }
    if (document.location.hash && document.location.hash !== "#") {
        return decode(document.location.hash.substr(1)).then((content) => {
            return { content, ...props };
        }, (error) => {
            console.error("decoding error", error);
            return props;
        });
    }
    return Promise.resolve(props);
}
