import { LZMA } from "./lzma";
import { fromByteArray, toByteArray } from "base64-js";
const { compress, decompress } = LZMA();
const BASE64_ENCODE_MAP = { "+": "-", "/": "_" };
const BASE64_DECODE_MAP = { "-": "+", _: "/" };
function b64enc(data) {
    return fromByteArray(data).replace(/[+/]/g, (c) => BASE64_ENCODE_MAP[c] || c);
}
function b64dec(data) {
    return toByteArray(data.replace(/[_-]/g, (c) => BASE64_DECODE_MAP[c] || c));
}
export async function encode(content, progress) {
    const data = await new Promise((resolve, reject) => {
        compress(content, 9, (data, error) => {
            if (error) {
                reject(error);
            }
            else {
                resolve(data || new Uint8Array(0));
            }
        }, progress);
    });
    return b64enc(data);
}
export async function decode(content, progress) {
    const data = b64dec(content);
    const decoder = new TextDecoder();
    return new Promise((resolve, reject) => {
        decompress(data, (data, error) => {
            if (error) {
                reject(error);
            }
            else {
                resolve(decoder.decode(data));
            }
        }, progress);
    });
}
