import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { Extensions } from "./extensions";
import { EventsExtension } from "./events-extension";
import { throttle } from "throttle-debounce";
import "./editor.scss";
export function Editor({ content, setContent, setTitle } = {}) {
    const editor = useEditor({
        content,
        extensions: [
            Extensions,
            EventsExtension.configure({
                onTitle: setTitle && throttle(300, setTitle),
                onHTML: setContent && throttle(300, setContent),
            }),
        ],
    });
    return React.createElement(EditorContent, { editor: editor });
}
