import { Extension } from "@tiptap/core";
export const EventsExtension = Extension.create({
    name: "eventsExtension",
    addStorage() {
        return {
            title: null,
        };
    },
    onTransaction({ transaction }) {
        const { content } = transaction.doc;
        const { onTitle, onHTML } = this.options;
        if (onTitle) {
            const title = findTitle(content);
            if (title !== this.storage.title) {
                this.storage.title = title;
                onTitle(title);
            }
        }
        if (onHTML) {
            onHTML(this.editor.getHTML());
        }
    },
});
function findTitle(content) {
    for (let i = 0; i < content.childCount; i++) {
        const child = content.child(i);
        if (child && child.type.name === "heading" && child.attrs.level === 1) {
            return child.textContent;
        }
    }
    return null;
}
